@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: 'Montserrat' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.username-text {
  text-align: left;
  letter-spacing: 0px;
  color: #091956;
}

.menu-items-text,
.menu-items-text .ant-menu-item {
  letter-spacing: 0px !important;
  color: #091956 !important;
  font-size: 15px;
  background-color: transparent !important;
  border: unset;
  /* padding: 0 !important; */
}



.menu-items-text li.ant-menu-submenu-selected div {
  color: #091956 !important;
}

.menu-items-text li.ant-menu-item-active::after,
.menu-items-text li.ant-menu-submenu-active::after,
.menu-items-text li.ant-menu-submenu-selected::after,
.menu-items-text li.ant-menu-item-selected::after {
  content: '' !important;
  break-after: always;
  border-bottom-color: #091956 !important;
}

.menu-border-right {
  border: 1px solid #091956 !important;
}

.menu-bg {
  background: #091956 0% 0% no-repeat padding-box;
  opacity: 0.9;

}

.footer-text {
  text-align: left;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.view-title-text {
  text-align: left;
  letter-spacing: 0px;
  font-size: 21px;
}

.view-title-container {
  width: 180px;
  border-left: 3px solid #A89EE0;
  /* margin-top: 300px; */
}

.view-container {
  padding: 550px 100px 0 100px;
  background: transparent;
  min-height: 1300px;
}

.section-content-text {
  text-align: left;
  letter-spacing: 0px;
  color: #091956;
}

.section-content-container {
  max-height: 200px;
  overflow-y: auto;
}

.section-content-title {
  text-align: left;
  letter-spacing: 0px;
  color: #A89EE0;
  width: 100%;
  font-size: 20px;
}

.section-card-container {
  background: #A89EE0 0% 0% no-repeat padding-box;
  background-image:
    linear-gradient(45deg,
      white 20%,
      #A89EE0 150%);
  min-height: 300px;
  width: 185px;
  cursor: pointer;
}

.section-card-text {
  text-align: center;
  letter-spacing: 0px;
  color: #091956;
  opacity: 1;
  font-size: 18px;
}


ul.ant-menu.ant-menu-vertical {
  background: #091956 !important;
  color: #FFFFFF !important;
  border-radius: 0px !important;
  padding: 0 10px !important;
}

ul.ant-menu.ant-menu-vertical li {
  border-bottom: 1px solid #A89EE0 !important;
  border-radius: 0px !important;
}

ul.ant-menu.ant-menu-vertical li:last-of-type {
  border-bottom: unset !important;
}

ul.ant-menu.ant-menu-vertical li:hover {
  color: #A89EE0 !important;
}

ul.ant-menu.ant-menu-vertical li div.ant-menu-submenu-title:hover {
  color: #A89EE0 !important;
}

ul.ant-menu.ant-menu-vertical li span:hover {
  color: #A89EE0 !important;
}



div.ant-menu-submenu ul li.ant-menu-submenu-selected div.ant-menu-submenu-title,
li.ant-menu-item-selected {
  color: #A89EE0 !important;
  background: #091956 !important;
}

.bg-dark-footer {
  background-color: rgb(28, 44, 86) !important;
}

.profile-menu-container {
  background: #091956 !important;
  color: #FFFFFF !important;
  opacity: 1 !important;
}

.profile-menu-item {
  border-bottom: 1px solid #A89EE0;
}

.ant-popconfirm .ant-btn-primary {
  background-color: #1677ff !important;
}



/* new styles */

.project-title {
  border-bottom: 10px solid rgba(0, 0, 0, 0.2);
}

.advisory .borders-error.ant-form-item.ant-form-item-has-error {
  border: 1px solid #ff4d4f !important;
  border-radius: 5px;
}

.ant-form-item-control>div:last-child {
  display: block !important;
}

.advisory .checkbox-open-input.ant-form-item.ant-form-item-has-error {
  border: unset !important;
}

.advisory .ant-form-item {
  margin-bottom: 5px !important;
}

.input-number .ant-form-item-control-input-content {
  display: flex !important;
  justify-content: left !important;
  margin-left: 10px;
}

.ant-form-item-label>label {
  height: fit-content !important;
  align-items: start !important;
}

.ant-form-item-required::before {
  display: none !important;
}